import moment from "moment";

export function utc2beijing(utcTime) {
  let time = moment(utcTime * 1000).format("YYYY-MM-DD HH:mm");
  return time;
}

export function statusFilter(object) {
  let color = "";
  if (object == 1) {
    color = "success";
  } else if (object == 2) {
    color = "teal lighten-3";
  } else if (object == 0) {
    color = "pink";
  } else if (object == 3) {
    color = "indigo darken-1";
  } else if (object == 4) {
    color = "yellow darken-2";
  } else if (object == 5) {
    color = "blue-grey";
  } else if (object == 6) {
    color = "brown";
  }
  return color;
}

export function statusTransfer(object) {
  let type = "";
  if (object == 1) {
    type = "个训";
  } else if (object == 2) {
    type = "集体";
  } else if (object == 0) {
    type = "缺席";
  } else if (object == 3) {
    type = "影子";
  } else if (object == 4) {
    type = "辅课";
  } else if (object == 5) {
    type = "20MIN";
  } else if (object == 6) {
    type = "30MIN";
  }
  return type;
}
