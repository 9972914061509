<template>
  <v-main>
    <div>
      <v-app-bar color="teal lighten-3" dense dark>
        <v-btn icon @click="clickOnLeft">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>打卡</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
    </div>
    <v-snackbar v-model="snackbar" bottom :color="msgColor" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="revoke">
          <v-icon left>mdi-undo-variant</v-icon> 撤销
        </v-btn>
      </template>
    </v-snackbar>
    <div class="ma-3 text-center">
      <h2>{{ studentName }}</h2>
      <div class="text-md-body-1">今日打卡次数: {{ kick_count }}</div>
    </div>
    <div class="text-center" style="border: 1px solid #f5f5f5; margin-bottom: 7px">
      <v-row>
        <v-col cols="3">
          <v-btn large icon color="red lighten-3" :loading="loading" :disabled="hiding" @click="signIn(0)">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
          <p>缺席</p>
        </v-col>
        <v-col cols="3">
          <v-btn color="blue-grey" large icon :loading="loading" :disabled="hiding" @click="signIn(5)">
            <v-icon dark>mdi-account-convert</v-icon></v-btn>
          <p>20MIN</p>
        </v-col>
        <v-col cols="3">
          <v-btn color="brown" large icon :loading="loading" :disabled="hiding" @click="signIn(6)">
            <v-icon dark>mdi-home-floor-3</v-icon></v-btn>
          <p>30MIN</p>
        </v-col>
        <v-col cols="3">
          <v-btn color="yellow darken-2" large icon :loading="loading" :disabled="hiding" @click="signIn(4)">
            <v-icon dark>mdi-account-heart</v-icon></v-btn>
          <p>辅课</p>
        </v-col>
        <v-col cols="3">
          <v-btn large icon color="indigo darken-1" :loading="loading" :disabled="hiding" @click="signIn(3)">
            <v-icon dark>mdi-bee</v-icon></v-btn>
          <p>影子</p>
        </v-col>
        <v-col cols="3">
          <v-btn large icon color="teal lighten-3" :loading="loading" :disabled="hiding" @click="signIn(2)">
            <v-icon dark>mdi-account-multiple</v-icon></v-btn>
          <p>集体</p>
        </v-col>
        <v-col cols="3">
          <v-btn large icon color="success" :loading="loading" :disabled="hiding" @click="signIn(1)">
            <v-icon dark>mdi-account</v-icon></v-btn>
          <p>个训</p>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-card-text class="py-0">
        <v-timeline>
          <v-timeline-item v-for="n in signData" :key="n.time" :color="n.status | colorFilter" small right>
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>{{ n.created_at | transferTime }}</strong>
              </v-col>
              <v-col>
                <div class="caption">{{ n.teacher_name }}</div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </div>
  </v-main>
</template>

<script>
import { getRecord, createRecord, deleteRecord } from "@/api";
import { statusFilter } from "@/utils";

import moment from "moment";

export default {
  data() {
    return {
      snackbar: false,
      text: "",
      timeout: 8000,
      msgColor: "",
      loading: false,
      hiding: false,
      studentName: this.$route.query.nick_name,
      id: parseInt(this.$route.query.id),
      signData: [],
      kick_count: "",
      user_id: parseInt(localStorage.getItem("id")),
      status: "",
    };
  },
  mounted() {
    this.getRecordByStudentId();
  },

  filters: {
    colorFilter(object) {
      let color = statusFilter(object);
      return color;
    },
    transferTime(utcTime) {
      let time = moment(utcTime * 1000).format("HH:mm");

      return time;
    },
  },
  methods: {
    getRecordByStudentId() {
      // 获取当天的打卡记录
      let startDate = moment().format("YYYY-MM-DD 00:00:00");
      let end_at = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS");
      // 1s内避免重复打卡
      // const expired_time = 1;
      getRecord({
        student_id: parseInt(this.$route.query.id),
        start_at: startDate,
        end_at: end_at,
      }).then((res) => {
        this.signData = res.data.data.records;
        this.kick_count = res.data.data.length;

        // if (res.data.data.length > 0) {
        //   let latestSignTime = this.signData.slice(-1)[0].created_at * 1000;
        //   let currentTime = moment().valueOf();
        //   let latacy = moment(currentTime).diff(
        //     moment(latestSignTime),
        //     "seconds"
        //   );

        //   if (latacy < expired_time) {
        //     this.hiding = true;
        //   }
        // }
      });
    },
    revoke() {
      deleteRecord({ id: this.signData.slice(-1)[0].id }).then((res) => {
        this.snackbar = false;

        if (res.data.code == "200") {
          this.getRecordByStudentId();
          this.hiding = false;
        }
      });
    },

    signIn(action) {
      this.loading = true;

      if (action == 0) {
        this.status = 0;
      } else if (action == 1) {
        this.status = 1;
      } else if (action == 2) {
        this.status = 2;
      } else if (action == 3) {
        this.status = 3;
      } else if (action == 4) {
        this.status = 4;
      } else if (action == 5) {
        this.status = 5;
      } else if (action == 6) {
        this.status = 6;
      }
      createRecord({
        status: this.status,
        student_id: this.id,
        user_id: this.user_id,
      }).then((res) => {
        if (res.data.code == "200") {
          this.snackbar = true;
          this.text = "签到成功";
          this.msgColor = "success";
          this.loading = false;
        } else {
          this.snackbar = true;
          this.text = res.data.msg;
          this.msgColor = "error";
          this.loading = false;
        }
        this.getRecordByStudentId();
      });
    },
    clickOnLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
>
