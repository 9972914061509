<template>
  <v-card class="mt-4 mx-auto" max-width="400">
    <v-card-text style="position: relative; flex: 1">
      <v-container style="display: flex; flex-direction: column; height: 100%">
        <v-row>
          <v-icon size="x-large" color="success">mdi-calendar-check</v-icon>
          <v-col cols="5" sm="5">
            <v-dialog ref="dialog" v-model="modal" :return-value.sync="record_form.date" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="record_form.date" label="月份" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="record_form.date" type="month" locale="zhHans" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  取消
                </v-btn>
                <v-btn text color="primary" @click="listStudyRecord('search')">
                  确定
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row>
          <v-col cols="2" sm="2">
            <span class="total-count">{{ total }}</span>
            <p class="total-head">总</p>
          </v-col>
          <v-col cols="2" sm="2">
            <span class="total">{{ single }}</span>
            <p class="total-head">个训</p>
          </v-col>
          <v-col cols="2" sm="2">
            <span class="total">{{ group }}</span>
            <p class="total-head">集体</p>
          </v-col>
          <v-col cols="2" sm="2">
            <span class="total">{{ connect }}</span>
            <p class="total-head">20MIN</p>
          </v-col>
          <v-col cols="2" sm="2">
            <span class="total">{{ halfHourLesson }}</span>
            <p class="total-head">30MIN</p>
          </v-col>
          <v-col cols="2" sm="2">
            <span class="total">{{ subsidiary }}</span>
            <p class="total-head">辅课</p>
          </v-col>
          <v-col cols="2" sm="2">
            <span class="total">{{ shadow }}</span>
            <p class="total-head">影子</p>
          </v-col>
        </v-row>
        <v-row style="flex: 1">
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in teacher_detail" :key="item.name">
                    <td class="td-font" style="width: 70px; padding: 0">
                      {{ item.name }}
                    </td>
                    <td style="padding: 0; width: 35px">{{ item.single }}</td>
                    <td>{{ item.group }}</td>
                    <td>{{ item.connect }}</td>
                    <td>{{ item.halfHourLesson }}</td>
                    <td style="width: 50px">{{ item.subsidiary }}</td>
                    <td style="padding-left: 30px; width: 30px">
                      {{ item.shadow }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
      <v-speed-dial absolute v-model="fab" right bottom direction="top" :open-on-hover="hover" :transition="transition"
        style="bottom: -20px">
        <template v-slot:activator>
          <v-btn v-model="fab" dark color="blue darken-2" fab style="height: 50px; width: 50px">
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-backup-restore</v-icon>
          </v-btn>
        </template>
        <v-btn fab dark small color="green" @click="handleClick">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-speed-dial>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">补卡</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select v-model="form.id" :items="student_list" label="学生*" required multiple
                    :menu-props="{ maxHeight: 200 }" chips persistent-hint></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="form.created_at" label="选择时间" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="form.created_at" locale="zh-cn" multiple></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select :items="items" v-model="form.status" label="课程*"></v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*必选项</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="reset">关闭</v-btn>
            <v-btn color="blue darken-1" text @click="checkIn">提交</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" bottom :color="msgColor" :timeout="timeout">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="revoke">
            <v-icon left>mdi-undo-variant</v-icon> 撤销
          </v-btn>
        </template>
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  studentList,
  deleteRecord,
  createBatchRecord,
  getRecordByUserId,
} from "@/api";
import moment from "moment";

export default {
  data: () => ({
    snackbar: false,
    text: "",
    timeout: 7000,
    msgColor: "",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    dialog: false,
    date: new Date().toISOString().substr(0, 10),
    student_list: [],
    study_record_id: [],
    total: 0,
    single: 0,
    group: 0,
    shadow: 0,
    connect: 0,
    subsidiary: 0,
    halfHourLesson: 0,
    sign_time: [],
    form: {
      id: [],
      status: undefined,
      // created_at: new Date().toISOString().substr(0, 10),
      created_at: [],
      user_id: parseInt(localStorage.getItem("id")),
    },

    record_form: {
      date: new Date().toISOString().substring(0, 7),
    },

    menu: false,
    modal: false,
    menu2: false,
    transition: "slide-y-reverse-transition",
    teacher_detail: [],
    items: [
      { text: "缺席", value: "0" },
      { text: "个训", value: "1" },
      { text: "集体", value: "2" },
      { text: "影子", value: "3" },
      { text: "辅课", value: "4" },
      { text: "20MIN", value: "5" },
      { text: "30MIN", value: "6" },
    ],
  }),

  mounted() {
    this.getStudentList();
    this.listStudyRecord();
  },

  methods: {
    handleClick() {
      this.dialog = true;
    },

    getStudentList() {
      studentList({}).then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          const student = {};
          student["text"] = res.data.data[i].nick_name;
          student["value"] = res.data.data[i].ID;

          this.student_list.push(student);
        }
      });
    },

    listStudyRecord(param) {
      this.$refs.dialog.save(this.record_form.date);

      if (param === "search") {
        let month = moment(this.record_form.date);
        let startTime = month
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss.SSS"); //月初
        let endTime = month.endOf("month").format("YYYY-MM-DD HH:mm:ss.SSS"); //月末
        getRecordByUserId({
          user_id: localStorage.getItem("id"),
          start_at: startTime,
          end_at: endTime,
        }).then((res) => {
          this.group = res.data.data.detail.group;
          this.single = res.data.data.detail.single;
          this.total = res.data.data.detail.total;
          this.shadow = res.data.data.detail.shadow;
          this.connect = res.data.data.detail.connect;
          this.halfHourLesson = res.data.data.detail.halfHourLesson;
          this.subsidiary = res.data.data.detail.subsidiary;
          this.teacher_detail = res.data.data.detail.user_detail;
        });
      } else {
        let month = moment();
        let startTime = month
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss.SSS"); //月初
        let endTime = month.endOf("month").format("YYYY-MM-DD HH:mm:ss.SSS"); //月末
        getRecordByUserId({
          user_id: localStorage.getItem("id"),
          start_at: startTime,
          end_at: endTime,
        }).then((res) => {
          this.group = res.data.data.detail.group;
          this.single = res.data.data.detail.single;
          this.total = res.data.data.detail.total;
          this.shadow = res.data.data.detail.shadow;
          this.connect = res.data.data.detail.connect;
          this.halfHourLesson = res.data.data.detail.halfHourLesson;
          this.subsidiary = res.data.data.detail.subsidiary;
          this.teacher_detail = res.data.data.detail.user_detail;
        });
      }
    },

    revoke() {
      for (let i = 0; i < this.study_record_id.length; i++) {
        deleteRecord({ id: this.study_record_id[i] }).then(() => { });
      }
      this.snackbar = false;
    },
    checkIn() {
      if (this.form.id == undefined || this.form.status == undefined) {
        this.snackbar = true;
        this.text = "表单验证失败";
        this.msgColor = "error";
        this.loading = false;
      } else {
        // this.form.created_at =
        //   this.form.created_at + " " + moment().format("HH:mm:ss");
        for (let i = 0; i < this.form.created_at.length; i++) {
          this.sign_time.push(moment(this.form.created_at[i]).utc().format());
        }
        createBatchRecord({
          status: parseInt(this.form.status),
          student_ids: this.form.id,
          time_set: this.sign_time,
          user_id: this.form.user_id,
        }).then((res) => {
          if (res.data.code == "200") {
            this.dialog = false;

            for (let i = 0; i < res.data.data.length; i++) {
              this.study_record_id.push(res.data.data[i].ID);
            }
            // this.snackbar = true;
            // this.text = "补卡成功";
            // this.msgColor = "success";
          } else {
            this.dialog = false;
            this.snackbar = true;
            this.text = res.data.msg;
            this.msgColor = "error";
          }
          this.reset();
        });
      }
    },

    reset() {
      this.dialog = false;
      this.form = {
        id: undefined,
        status: undefined,
        created_at: [],
        user_id: parseInt(localStorage.getItem("id")),
      };
    },
  },
  computed: {
    activeFab() {
      switch (this.tabs) {
        case "one":
          return { class: "green", icon: "keyboard_arrow_up" };
        default:
          return {};
      }
    },
  },
  watch: {
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
  },
};
</script>

<style>
th {
  height: 0 !important;
  /* 使用 !important 可以确保样式生效 */
}

.td-font {
  font-weight: 550;
  /* 使用 !important 可以确保样式生效 */
}

/* .v-sheet--offset {
  top: -24px;
  position: relative;
} */

.total {
  font-size: 22px;
  font-weight: 600;
}

.total-head {
  font-size: 12px;
}

.total-count {
  font-size: 27px;
  font-weight: 600;
  color: #1976d2;
}
</style>
